<template>
  <div>
    <slot name="info"></slot>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title + ` №${data.code_doc}` : "Новый документ '" + m.title + "'" }}
        [{{ statusCurrent.text || "" }}]
        <br />
        {{ config.name }}
      </template>
      <a-loader v-if="loading" />
      <v-row v-else>
        <v-col cols="12" sm="12" class="pb-0">
          <v-btn v-if="(!id && !document.id) || (data.import_id && !document.id)" @click="showSelectDialog = true"> Выбрать документ </v-btn>
        </v-col>
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0 pt-3 align-self-center">
          <a-form-view
            v-if="el.name == 'balance1'"
            class="pb-1"
            :value="balance"
            :model="[
              {
                name: 'credit',
                title: 'Всего к оплате по документу',
                type: 'number',
              },
              {
                name: 'debit',
                title: 'Всего выплачено по документу',
                type: 'number',
              },
              {
                name: 'saldo',
                title: 'Не выплачено по документу',
                value: balance.credit - balance.debit || '0',
                type: 'number',
              },
            ]"
            @click="getBalance()"
            :config="{ dense: true, size: 24 }"
          />
          <div v-else v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name])"
              :errors="errors"
              :config="{ dense: true, readonly, hideDetails: 'auto' }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>

      <template v-slot:actions>
        <a-btn-status v-if="!loading && isStatus" :items="statusItems" @click="changeStatus($event)" :disabled="!canStatus" />
        <v-btn v-if="!loading && data.status == 2" :href="getPreview()" target="_blank" class="mx-1" dark color="info" title="Печать">
          <v-icon dark>fas fa-print </v-icon>
        </v-btn>
        <a-btn-acc-record v-if="data.status" :id="id" :name="m.accDocName" />
        <v-spacer></v-spacer>
        <a-btn-import-data v-if="data.import_data" :value="data.import_data" :model="{ name: 'import_data', title: 'Данные импорта' }" />
        <a-btn-delete v-if="!loading && id && isDelete" @click="removeDialogShow = true" />
        <a-btn-save v-if="!loading && isEdit" @click="submit()" :color="isChanged ? 'green' : 'primary'" />

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <select-dialog
      v-model="showSelectDialog"
      v-if="showSelectDialog"
      :config="config"
      :vendor_id="data.import_id ? data.vendor_id : null"
      @select="onSelectDocument($event)"
    />
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";
import libsCashAccess from "./../../libsCashAccess";

export default {
  components: {
    selectDialog: () => import("./selectDialog"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel, libsCashAccess],
  props: {
    value: Boolean,
    id: Number,
    initData: Object,
    document: {
      type: Object,
      default: () => {
        return {};
      },
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      m: this.config.m,
      api: this.config.m.api,
      apiStatus: this.config.m.api,
      removeDialogShow: false,
      showSelectDialog: false,
      loaded: false,
      baseId: null,
      modelForm: null,
      debit: null,
      credit: null,
      isPrint: false,
    };
  },
  computed: {
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    model: {
      get() {
        return this.calcModel();
      },
    },

    canStatus() {
      return !this.loading && !this.isChanged && this.data.id;
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statuses = this.m.statuses;
      const statusCur = statuses.find((el) => el.value == this.data.status);
      if (statusCur && statusCur.next && statuses) {
        let arr = statusCur.next;
        res = statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      return items;
    },
    statusCurrent() {
      let statusCur = this.m.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (this.id) {
    } else {
      this.onInitData();
      this.data.parent_name = this.config.document.accDocName || this.initData?.parent_name;
      this.data.operation_type = this.config.id;
      if (this.document?.id) {
        this.onSelectDocument(this.document);
      } else {
        this.showSelectDialog = true;
      }
    }
  },
  watch: {
    value1() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.type = 1;
          //  this.data.status = 0;
        }
      }
    },
  },
  methods: {
    getPreview() {
      return this.$root.config.baseUrl + this.api + `/${this.id}/print?token=` + localStorage.getItem("user-token");
    },
    onInitData() {
      if (!this.initData) return;
      for (const key in this.initData) {
        this.data[key] = this.initData[key];
      }
    },
    onSelectDocument(e) {
      //console.log("onSelectDocument", e, this.config);
      this.loading = true;
      if (this.config.document?.fieldsConfig) {
        const config = this.config.document?.fieldsConfig;
        //console.log(config);
        for (const key in config) {
          if (this.data.hasOwnProperty(key) || true) {
            if (config[key].type === "field") {
              let v = e[config[key].value];
              if (config[key]?.dataType == "number") v = parseInt(v);
              this.setData(key, v);
              this.validate(key);
            } else if (config[key].type === "value") {
              this.setData(key, config[key].value);
              this.validate(key);
            } else {
              console.log("не известный тип сопоставления данных " + config[key].type);
            }
          } else {
            console.log("не найдено поле для сопоставления данных " + key);
          }
        }
      } else {
        if (!this.data.data) this.data.data = {};
        this.data.data.type = e.type;
        this.data.data.object_code = parseInt(e?.object_code);
        this.data.object_id = e.type == 1 ? parseInt(e?.object_code) : null;
        this.data.vendor_id = e.vendor_id;
      }
      this.data.parent_id = e.id;
      if (!this.data.parent_name) this.data.parent_name = this.config.document.accDocName;
      this.validate("parent_id");
      this.validate("parent_name");
      this.getBalance();
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.isChanged = false;
      return;
      if (status) this.$emit("input");
    },
    afterFetchData(r) {
      if (this.id) {
        this.getBalance();
        //   this.calcModel();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },
    async getBalance() {
      let doc_id = this.data.parent_id;
      if (!doc_id) return;
      doc_id = String(doc_id);
      let debit = 0;
      let credit = 0;
      const acc = this.config.debit;
      let q = `
select (SELECT  sum(value)  FROM accounting_record	where  debit_account=${acc} and debit_subconto_value_2=${doc_id}) as debit,
   (SELECT  sum(value) FROM accounting_record	where  credit_account=${acc} and credit_subconto_value_2=${doc_id}) as credit
`;
      let balance = null;
      try {
        let resp = await this.$axios.post("/accounting/records/report-sql", { q });
        balance = resp.data.data[0];
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при получении баланса счета",
        });
        return;
      }

      this.credit = balance.credit;
      this.debit = balance.debit;
    },
    calcModel(t) {
      //оплата заказа товаров...
      const config = this.config;
      this.isPrint = config?.isPrint || false;
      let model = JSON.parse(JSON.stringify(this.m.form));
      if (config?.fieldsReplace) {
        Object.keys(config.fieldsReplace).forEach((f) => {
          let idx = model.findIndex((el) => {
            return el.name == f;
          });
          if (idx !== -1) model[idx] = config.fieldsReplace[f];
          else model.push(config.fieldsReplace[f]);
        });
      }
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });
      this.modelForm = mForm;
      return model;
    },
    async changeStatus(status) {
      if (await this.validateAll(this.data, true)) {
      } else {
        return;
      }
      if (this.credit && status.value == 2) {
        if (this.config?.balanceCheck || this.config?.balanceCheck === undefined) {
          await this.getBalance();
          console.log(this.credit, this.debit, this.data.value, parseFloat((this.credit - this.debit - this.data.value).toFixed(4)));
          if (parseFloat(((this.credit || 0) - (this.debit || 0) - this.data.value).toFixed(4)) < 0) {
            console.log(this.credit, this.debit, this.data.value, parseFloat((this.credit - this.debit - this.data.value).toFixed(4)));
            this.$root.$emit("show-info", {
              type: "error",
              text: "ОШИБКА. Баланс по документу отрицательный",
            });
            return false;
          }
        }
      }
      let id = this.data.id;
      if (!id) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Что-то пошло не так....",
        });
        return;
      }
      this.loading = true;
      let data = { id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
      this.getBalance();
    },
  },
};
</script>
