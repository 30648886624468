/** @format */

export default {
  data() {
    return {};
  },
  computed: {
    isEdit() {
      if (!this.data.id) return true;
      return this.data.status != 2 && this.getAccess(`documents.${this.config.m.accessName}.edit`, { users: [this.data.createdby_id] });
    },
    isDelete() {
      return this.data.status != 2 && this.getAccess(`documents.${this.config.m.accessName}.delete`, { users: [this.data.createdby_id] });
    },
    isStatus() {
      return this.getAccess(`documents.${this.config.m.accessName}.status`);
    },
    readonly() {
      if (this.$root.profile.role == 20) return true;
      return false;
    },
  },
  methods: {},
};
